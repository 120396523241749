import Layout from "../components/Layout";
import "../styles/globals.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";

function MyApp({ Component, pageProps }) {

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;

// const router = useRouter();
// const [loading, setLoading] = useState(false);

// const [preload, setPreLoad] = useState(true);

// useEffect(() => {
//   const loadData = async () => {
//     await new Promise((r) => setTimeout(r, 3000));
//     setPreLoad(!preload);
//   };
//   loadData();
// }, [])


// useEffect(() => {
//   const handleStart = (url) => (url !== router.asPath) && setLoading(true);
//   const handleComplete = (url) => (url === router.asPath) && setLoading(false);

//   router.events.on('routeChangeStart', handleStart)
//   router.events.on('routeChangeComplete', handleComplete)
//   router.events.on('routeChangeError', handleComplete)

//   return () => {
//     router.events.off('routeChangeStart', handleStart)
//     router.events.off('routeChangeComplete', handleComplete)
//     router.events.off('routeChangeError', handleComplete)
//   }
// })
import React, { useState } from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import styles from "../styles/components/Header.module.scss";
import Head from "next/head";
import {
    FaFacebookF,
    FaInstagram,
    FaLinkedinIn,
    FaYoutube,
    FaTwitter,
} from "react-icons/fa";
import { IoMdCall } from "react-icons/io";

const Header = () => {
    const [isactive, setIsactive] = useState(false);

    const handleIsActive = () => {
        setIsactive(!isactive);
    };

    return (
        <>
            <Head>
                <meta
                    name="google-site-verification"
                    content="pcRsn0yT8VT1G2Rw-PQKVgnDp666tp0cXs1I5pxpvLY"
                />
            </Head>

            <header className={styles.header}>
                <div className={`${styles.header_bottom} w-100`}>
                    <div className={styles.header_container}>
                        <div className={styles.header_flex}>
                            <div className={styles.logo}>
                                <Link href="/">
                                    <Image
                                        src="/icons/logo.svg"
                                        width={240}
                                        height={65}
                                        alt="Twin-Tower Logo"
                                    ></Image>
                                </Link>
                            </div>
                            <div className={styles.mobile_logo}>
                                <Link href="/">
                                    {/* <Image src="/icons/mobile-logotwo.png" alt="Twin-Tower logo" width={500}
                                        height={200} /> */}
                                    <Image
                                        src="/icons/mobile-logotwo.png"
                                        width={200}
                                        height={53}
                                        alt="Logo"
                                    ></Image>
                                </Link>
                            </div>
                            <div className={styles.header_right}>
                                <nav className={`${styles.navbar} navbar-expand-lg`}>
                                    <div className="navbar-collapse" id="navbarNav">
                                        <ul className={styles.navbar_nav}>
                                            <li className={styles.nav_item}>
                                                <Link href="/about-us" className={styles.nav_link}>
                                                    About Us
                                                </Link>
                                            </li>
                                            <li className={styles.nav_item}>
                                                <Link href="/rooms-and-suites" className={styles.nav_link}>
                                                    Rooms & Suites
                                                </Link>
                                            </li>
                                            <li className={styles.nav_item}>
                                                <Link href="/bar-and-restaurant" className={styles.nav_link}>
                                                    Dining
                                                </Link>
                                            </li>
                                            <li className={styles.nav_item}>
                                                <Link href="https://www.adventureresortkufri.com" target="_blank" className={styles.nav_link}>
                                                    Adventure
                                                </Link>
                                            </li>
                                            {/* <li className={styles.nav_item}>
                                                <Link href="/#events" className={styles.nav_link}>
                                                    Events
                                                </Link>
                                            </li> */}
                                            <li className={styles.nav_item + ' ' + styles.dropdown}>
                                                <span className={styles.nav_link}>Events  <span className={styles.dropdown_icon}>▼</span></span>
                                                <ul className={styles.dropdown_menu}>
                                                    <li><Link href="/banquet-halls" className={styles.drop_down_nav_link}>Banquet Halls</Link></li>
                                                    <li><Link href="/conference-hall" className={styles.drop_down_nav_link}>Conference Hall</Link></li>
                                                </ul>
                                            </li>
                                            <li className={styles.nav_item}>
                                                <Link href="/gallery" className={styles.nav_link}>
                                                    Gallery
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                            <div className={styles.header_right_btn}>
                                <div>
                                    <Link href="tel:9318192605" className={styles.call_icon}>
                                        <IoMdCall className={styles.call_react_icon} />
                                        <p>CALL US</p>
                                    </Link>
                                </div>

                                <div>
                                    {/* <Link target="_blank" href="https://thetwintowers.bookingjini.com/property" className="common_btn">
                                        Book Now
                                    </Link> */}
                                    <a href="https://thetwintowers.bookingjini.com/property" target="_blank" rel="noopener noreferrer" className="common_btn">
                                        Book Now
                                    </a>
                                    {/* <BookNowButton  /> */}
                                </div>

                            </div>
                            <button onClick={handleIsActive} className={styles.header_toggle}>
                                {/* <Link href="/" className="d-flex"> */}
                                <Image
                                    src="/icons/menu.svg"
                                    width={20}
                                    height={20}
                                    alt="Twin-Tower Logo"
                                ></Image>
                                {/* </Link> */}
                            </button>
                        </div>
                    </div>
                </div>
            </header>

            {isactive ? (
                <header className={styles.header_trans}>
                    <div className={styles.mobile_header}>
                        <div className="container">
                            <div className={styles.mobile_header_div}>
                                <div className="logo">
                                    <Link className={styles.navbar_brand} href="/">
                                        <Image
                                            src="/icons/mobile-logotwo.png"
                                            width={200}
                                            height={53}
                                            alt="Twin-Tower Logo"
                                        ></Image>
                                    </Link>
                                </div>
                                <div>
                                    <button className={styles.navbar_cut_btn} onClick={handleIsActive}>
                                        <Image
                                            src="/icons/close.svg"
                                            width={18}
                                            height={18}
                                            alt="Twin-Tower Logo"
                                        ></Image>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <nav>
                                    <div>
                                        <div className={styles.res_header_links}>
                                            <ul className={styles.mobile_view_ul}>
                                                <li className={styles.mobile_view_lists}>
                                                    <Link href="/about-us" onClick={handleIsActive}>
                                                        About Us
                                                    </Link>
                                                </li>
                                                <li className={styles.mobile_view_lists}>
                                                    <Link href="/rooms-and-suites" onClick={handleIsActive}>
                                                        Rooms & Suites
                                                    </Link>
                                                </li>
                                                <li className={styles.mobile_view_lists}>
                                                    <Link href="/bar-and-restaurant" onClick={handleIsActive}>
                                                        Dining
                                                    </Link>
                                                </li>
                                                <li className={styles.mobile_view_lists}>
                                                    <Link href="/banquet-halls" onClick={handleIsActive}>
                                                        Banquet Halls
                                                    </Link>
                                                </li>
                                                <li className={styles.mobile_view_lists}>
                                                    <Link href="/conference-hall" onClick={handleIsActive}>
                                                        Conference Hall
                                                    </Link>
                                                </li>
                                                <li className={styles.mobile_view_lists}>
                                                    <Link href="/gallery" onClick={handleIsActive}>
                                                        Gallery
                                                    </Link>
                                                </li>
                                                <li className={styles.mobile_view_lists}>
                                                    <Link href="/contact-us" onClick={handleIsActive}>
                                                        Contact Us
                                                    </Link>
                                                </li>
                                            </ul>
                                            {/* <div> */}
                                            {/* <Link href="tel:9317192601" className={styles.call_icon}>
                                                    <Image src='/icons/header-call.svg' width={20} height={20}></Image>
                                                    <p>Call Us</p>
                                                </Link> */}
                                            {/* </div> */}
                                        </div>
                                        <div className={styles.header_right_btn}>
                                            {/* <Link target="_blank" href="https://thetwintowers.bookingjini.com/property" className="common_btn">
                                                Book Now
                                            </Link> */}
                                            <a href="https://thetwintowers.bookingjini.com/property" target="_blank" rel="noopener noreferrer" className="common_btn">
                                                Book Now
                                            </a>
                                            {/* <BookNowButton setIsactive={setIsactive} /> */}

                                            <Link href="tel:9318192605" className={styles.call_icon}>
                                                <Image src='/icons/header-call.svg' width={20} height={20} alt='Call Icon'></Image>
                                                <p>Call Us</p>
                                            </Link>
                                        </div>
                                    </div>
                                </nav>
                                <div className={styles.contact_social_links}>
                                    <div className={styles.icon}>
                                        <Link className={styles.fb} href="https://www.facebook.com/twintower.hotel" target="_blank">
                                            <FaFacebookF />
                                        </Link>
                                    </div>
                                    <div className={styles.icon}>
                                        <Link href="https://www.instagram.com/hotel_twintowers" className={styles.insta} target="_blank">
                                            <FaInstagram />

                                        </Link>
                                    </div>
                                    <div className={styles.icon}>
                                        <Link className={styles.linkedin} href="https://www.linkedin.com/in/the-twin-towers-hotel" target="_blank">
                                            <FaLinkedinIn />
                                        </Link>
                                    </div>
                                    <div className={styles.icon}>
                                        <Link className={styles.linkedin} href="https://www.youtube.com/@thetwintowershotel" target="_blank">
                                            <FaYoutube />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            ) : (
                ""
            )}
        </>
    )
}

export default Header;
